@font-face {
  font-family: 'NarolaNew';
  src:  url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/FontV2492021/NarolaNew.eot?mnncoi');
  src:  url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/FontV2492021/NarolaNew.eot?mnncoi#iefix') format('embedded-opentype'),
    url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/FontV2492021/NarolaNew.ttf?mnncoi') format('truetype'),
    url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/FontV2492021/NarolaNew.woff?mnncoi') format('woff'),
    url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/FontV2492021/NarolaNew.svg?mnncoi#NarolaNew') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'NarolaNew' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-rose_cut:before {
  content: "\e900";
}
.icon-Single_14:before {
  content: "\42";
}
.icon-Single_9:before {
  content: "\43";
}
.icon-Single_6:before {
  content: "\46";
}
.icon-Single_5:before {
  content: "\47";
}
.icon-Single_3:before {
  content: "\48";
}
.icon-Single_2:before {
  content: "\49";
}
.icon-round:before {
  content: "\4a";
}
.icon-up_arrow:before {
  content: "\4b";
}
.icon-search:before {
  content: "\4c";
}
.icon-all-shape:before {
  content: "\4d";
}
.icon-order1:before {
  content: "\4e";
}
.icon-nda:before {
  content: "\4f";
}
.icon-mail:before {
  content: "\50";
}
.icon-hold:before {
  content: "\51";
}
.icon-favrouite:before {
  content: "\52";
}
.icon-download:before {
  content: "\53";
}
.icon-down-arrow:before {
  content: "\54";
}
.icon-dashboard:before {
  content: "\55";
}
.icon-basket:before {
  content: "\56";
}
.icon-user:before {
  content: "\57";
}
.icon-key:before {
  content: "\58";
}
.icon-close:before {
  content: "\59";
}
.icon-plus:before {
  content: "\5a";
}
.icon-refresh:before {
  content: "\5b";
}
.icon-print:before {
  content: "\61";
}
.icon-my-wishlist:before {
  content: "\62";
}
.icon-excel:before {
  content: "\63";
}
.icon-cart-with-user:before {
  content: "\64";
}
.icon-add-wishlist:before {
  content: "\65";
}
.icon-add-basket:before {
  content: "\66";
}
.icon-Confirm-Stone:before {
  content: "\67";
}
.icon-Confirm-Stone-new:before {
  content: "\68";
}
.icon-Excel-Upload:before {
  content: "\69";
}
.icon-dollar:before {
  content: "\6a";
}
.icon-certificate:before {
  content: "\6b";
}
.icon-mail_send:before {
  content: "\6c";
}
.icon-view:before {
  content: "\6d";
}
.icon-save:before {
  content: "\6e";
}
.icon-logout:before {
  content: "\6f";
}
.icon-dot_menu:before {
  content: "\70";
}
.icon-Single_4:before {
  content: "\71";
}
.icon-remove_cart:before {
  content: "\72";
}
.icon-my_confirm:before {
  content: "\73";
}
.icon-new_arrival:before {
  content: "\74";
}
.icon-correct-round:before {
  content: "\75";
}
.icon-question:before {
  content: "\76";
}
.icon-gem:before {
  content: "\77";
}
.icon-old_Square-Emerald:before {
  content: "\78";
}
.icon-share:before {
  content: "\79";
}
.icon-whatsapp:before {
  content: "\7a";
}
.icon-wechat:before {
  content: "\e901";
}
.icon-Discount-Master:before {
  content: "\e902";
}
.icon-useractivation:before {
  content: "\e903";
}
.icon-skype:before {
  content: "\e904";
}
.icon-delete:before {
  content: "\e905";
}
.icon-order:before {
  content: "\e906";
}
.icon-medal:before {
  content: "\e907";
}
.icon-video:before {
  content: "\e908";
}
.icon-camera:before {
  content: "\e909";
}
.icon-Diamond-Search:before {
  content: "\e90a";
}
.icon-chart:before {
  content: "\e90b";
}
.icon-add_shopping_cart:before {
  content: "\e90c";
}
.icon-diamond:before {
  content: "\e90d";
}
.icon-certi:before {
  content: "\e90e";
}
.icon-camera_outlined:before {
  content: "\e90f";
}
.icon-videocam_outlined:before {
  content: "\e910";
}
.icon-alert:before {
  content: "\e911";
}
.icon-received:before {
  content: "\e912";
}
.icon-ship:before {
  content: "\e913";
}
.icon-transit:before {
  content: "\e914";
}
.icon-km-diamond:before {
  content: "\e915";
}
.icon-k-close:before {
  content: "\e916";
}
.icon-uniE916:before {
  content: "\e917";
}
.icon-uniE917:before {
  content: "\e918";
}
.icon-uniE918:before {
  content: "\e919";
}
.icon-Frame:before {
  content: "\e91a";
}
.icon-Frame-1:before {
  content: "\e91b";
}
.icon-Frame-2:before {
  content: "\e91c";
}
.icon-Frame-3:before {
  content: "\e91d";
}
.icon-other:before {
  content: "\e91e";
}
.icon-Frame-4:before {
  content: "\e91f";
}
.icon-Frame-5:before {
  content: "\e920";
}
.icon-Frame-6:before {
  content: "\e921";
}
.icon-Frame-7:before {
  content: "\e922";
}
.icon-Dossier-Round:before {
  content: "\e923";
}
.icon-Fancy-Shape:before {
  content: "\e924";
}
.icon-Full-Stock:before {
  content: "\e925";
}
.icon-Solitaire-Round:before {
  content: "\e926";
}
.icon-Single_17:before {
  content: "\e927";
}
.icon-Single_7:before {
  content: "\e928";
}
.icon-Single_8:before {
  content: "\e929";
}
.icon-success:before {
  content: "\e92a";
}
.icon-Cushion1:before {
  content: "\e92b";
}
.icon-ico_Princess:before {
  content: "\e92c";
}
.icon-Round1:before {
  content: "\e92d";
}
.icon-Square-Emerald:before {
  content: "\e92e";
}
.icon-CBrilliant1:before {
  content: "\e9b3";
}
.icon-old_Cushion1:before {
  content: "\e9b6";
}
.icon-Emerald1:before {
  content: "\e9bc";
}
.icon-Heart1:before {
  content: "\e9c6";
}
.icon-Marquise1:before {
  content: "\e9ce";
}
.icon-Oval1:before {
  content: "\e9d1";
}
.icon-Pear1:before {
  content: "\e9d2";
}
.icon-old_Round1:before {
  content: "\e9d8";
}
.icon-old_ico_Princess:before {
  content: "\eb39";
}
.icon-LgRadiant3:before {
  content: "\eb4a";
}
