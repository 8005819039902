/* You can add global styles to this file, and also import other style files */

@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';


body {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: Rubik;
  overflow-x: hidden;
}

* {
  outline: 0;
}

ul,
li {
  list-style: none;
}

.sch .mat-form-field-infix {
  background: #fffefe;
  border: 1px solid #e8ecef;
  border-radius: 6px;
  margin-right: 8px;
  padding: 8px;
}

body.modal-open {
  overflow: hidden;
}

.sch .mat-form-field-underline {
  display: none;
}

.sch .input.mat-input-element {
  padding: 10px;
}

.sch .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.sch .mat-form-field {
  margin-left: -4px;
}

.sch .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.sel_mat .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.date_sch .mat-mat-mdc-form-field-infix {
  background: #fffefe;
  border: 1px solid transparent;
  margin-right: 8px;
  padding: 8px;
  border-radius: 6px;
  width: 160px;
}

.modal.fade .modal-dialog {
  transform: translate(0) !important;
  height: 100%;
}


.date_sch .mat-mdc-form-field-underline {
  display: none;
}

.date_sch .input.mat-input-element {
  padding: 10px;
}

.date_sch .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0px;
}

/*.date_sch .mat-form-field {*/
/*padding-top: 6px;*/
/*}*/

.date_sch .mat-mdc-form-field-wrapper {
  padding-bottom: 0px;
}



.meas_sch .mat-mdc-form-field-infix {
  background: #fffefe;
  border: 1px solid #e8ecef;
  border-radius: 6px 0 0 6px;
  margin-right: 8px;
  padding: 8px;
  height: 20px;
}

a.fc_col:not(:first-child) {
  border-left: 0;
}

.sch_whfc a.fc_col {
  border-radius: 0;
}

.sch_whfc a.fc_col:last-child {
  border-radius: 0 6px 6px 0;
}

.meas_sch .mat-mdc-form-field-underline {
  display: none;
}

.meas_sch .input.mat-input-element {
  padding: 10px;
}

.meas_sch .mat-mdc-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.meas_sch .mat-mdc-form-field {
  line-height: normal;
}

.meas_sch .mat-mdc-form-field-wrapper {
  padding-bottom: 0px;
}

.org_mat .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.org_mat .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

.tot_min .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.tot_min .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

.tot_min11 .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.tot_min11 .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

.tot_min11cust .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.tot_min11cust .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

.tot_mincust .mat-mdc-form-field-infix {
  border-top: 0px;
  padding: 0px;
}

.tot_mincust .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  display: none;
}

/*.tbl_res tr:nth-child(odd) {
  background-color: #f2f2f2;
}*/

/* .org_mat .mat-select-arrow {
  margin-top: -15px;
}

.org_mat .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 10px;
  border: 1px solid #e8ecef;
  height: 35px;
  border-radius: 6px;
  background-color: transparent;
}

.org_mat .mat-focused {
  border-color: transparent;
}
 */

.custom-tooltip {
  font-size: 15px !important;
  padding: 7px 14px !important;
  font-weight: 100;
  letter-spacing: 1px;
  background-color: #267491;
}

/*table-css- in result grid*/
.res_ttbl tr.mat-row,
tr.mat-footer-row {
  height: 30px;
}

.res_ttbl th.mat-header-cell {
  text-align: center;
}

.res_ttbl tr.mat-header-row {
  height: 30px;
  background-color: #c1c1c1;
}

.res_ttbl .tbl_hesdr {
  color: #000;
}

.img_spin {
  width: 70px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #f2f2f2;
  z-index: 9999;
  text-align: center;
  padding-top: 400px;
  opacity: 0.9 !important;
}

.meas_sch .mat-mdc-form-field-infix {
  width: 150px;
}

@media screen and (max-width: 1600px) {
  .meas_sch .mat-mdc-form-field-infix {
    width: 151px;
  }

  .org_mat .mat-mdc-form-field-infix {
    width: 110px;
  }
}

@media screen and (max-width: 1440px) {
  .meas_sch .mat-mdc-form-field-infix {
    width: 128px;
  }

  .org_mat .mat-mdc-form-field-infix {
    width: 90px;
  }
}

@media screen and (max-width: 1366px) {
  .meas_sch .mat-mdc-form-field-infix {
    width: 126px;
  }

  .org_mat .mat-mdc-form-field-infix {
    width: 80px;
  }
}

@media screen and (max-width: 1280px) {
  .meas_sch .mat-mdc-form-field-infix {
    width: 115px;
  }
}

@media screen and (max-width: 1024px) {
  .meas_sch .mat-mdc-form-field-infix {
    width: 200px;
    margin-top: 10px;
  }
}


.top-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

@media screen and (max-width: 414px) {
  .sch .mat-mdc-form-field-infix {
    width: 115px;
  }

  .meas_sch .mat-mdc-form-field-infix {
    width: 100px;
  }

  .cdk-overlay-container img {
    max-width: 100%;
    width: auto !important;
  }

  .cdk-overlay-container iframe.det_vdeo {
    min-height: 380px;
    max-width: 430px;
    border: 0;
    width: 100% !important;
  }

  .rest-left-side {
    width: 100%;
  }

  .cdk-overlay-pane {
    width: 100% !important;
  }

  .ask-me-img-holder img {
    width: 100% !important;
  }

  .ask-me-img-holder {
    margin-bottom: 15px;
  }

  .kam-fields-group {
    padding: 0 10px;
  }
}

.gender_chip .mat-mdc-standard-chip:hover::after {
  opacity: .0;
}

.gender_chip .mat-mdc-standard-chip:focus::after {
  opacity: .0;
}

.change-bg {
  background: #d7d7d7;
}

.crt-holder .mat-mdc-chip-list-wrapper {
  margin-top: 0;
  margin-left: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.uact_ngdt ngb-datepicker {
  background-color: #fff;
  margin-top: 30px;
  margin-left: -40px;
}

input[type="search"],
select.form-control,
.frm_inact input {
  border: 1px solid #e8ecef !important;
  border-radius: 5px;
  outline: 0 !important;
  min-height: 38px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: none !important;
}

.dropdown-menu .ngb-dp-day,
.dropdown-menu .ngb-dp-week-number,
.dropdown-menu .ngb-dp-weekday {
  font-size: 12px;
  font-style: normal;
}

.ngb-dp-month .ngb-dp-week:last-child {
  display: none;
}

.dropdown-menu .ngb-dp-month-name {
  font-size: 14px;
  font-weight: 700;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu ngb-datepicker-navigation-select>.custom-select {
  border-radius: 5px;
  border-color: #e8ecef;
  margin: 0 5px;
}

.dropdown-menu .ngb-dp-month+.ngb-dp-month {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #e8ecef;
}

.dropdown-menu .ngb-dp-month+.ngb-dp-month .ngb-dp-month-name {
  padding-left: 0;
}

.dropdown-menu .ngb-dp-content.ngb-dp-months {
  border-top: 1px solid #e8ecef;
  margin-top: 5px;
  padding: 0 5px;
}

.dropdown-menu span.ngb-dp-navigation-chevron {
  opacity: 1;
  border-color: #267491;
}

.dataTables_info,
.dataTables_filter label,
.dataTables_length label,
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 700;
}

button.btn.btn-link.ngb-dp-arrow-btn {
  cursor: pointer;
}

.dropdown-menu .ngb-dp-weekdays .ngb-dp-weekday {
  color: #267491;
  font-weight: 700;
  height: 25px;
}

.dataTables_length select {
  border: 1px solid #e8ecef !important;
  border-radius: 5px !important;
  font-size: 12px;
  padding: 5px;
}

.dataTables_wrapper .dataTables_filter input {
  padding-left: 5px;
}

table.dataTable.no-footer {
  border-bottom: 0px solid #111111 !important;
}

select.form-control option {
  outline: 0 !important;
}

.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #fff !important;
  border: 1px solid #267491 !important;
  background-color: white !important;
  background: #267491 !important;
}

.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important;
  border: 1px solid #267491 !important;
  background-color: white !important;
  background: #267491 !important;
}

.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.table-responsive .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #fff !important;
}

@media screen and (max-width: 414px) {
  .uact_ngdt ngb-datepicker {
    margin-left: -2px;
    margin-top: 38px;
  }

  .uact_ngdt .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 1.5rem !important;
  }
}

@media screen and (max-width: 375px) {
  .uact_ngdt ngb-datepicker {
    margin-left: -25px;
  }
}

@media (max-width: 1700px) and (min-width: 1300px) {
  .meas_sch .mat-mdc-form-field {
    max-width: 175px;
  }
}

.meas_sch .mat-mdc-form-field {
  max-width: 175px;
}

.ua-table th,
.dism-table th {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0 6px !important;
  text-align: center !important;
  font-size: 12px;
  font-weight: 700 !important;
  background-image: none !important;
  font-family: Rubik;
  vertical-align: middle !important;
}

.ua-table th b,
.dism-table th b {
  font-size: 12px;
  font-weight: 700 !important;
}

.ua-table td,
.dism-table td {
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: 0 6px !important;
  text-align: center;
  font-size: 12px;
  font-family: Rubik;
  color: #253646;
  height: 30px;
  vertical-align: middle !important;
}

.ua-table thead tr,
.dism-table thead tr {
  height: 30px;
  background-color: #c1c1c1;
}

.ua-table tbody tr,
.dism-table tbody tr {
  height: 30px;
}

.ua-table td button,
.dism-table td button {
  background: transparent;
  border: 0;
  font-size: 14px;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(38, 116, 145, .7);
  top: 0;
  left: 0;
  z-index: 109999;
}

.modal-dialog {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.in-b .mat-mdc-chip-list-wrapper {
  margin: 0;
}

.mat-mdc-chip-list-wrapper {
  margin: 0 !important;
}



.grid-table .ag-theme-quartz .ag-root-wrapper {
  border: 0px;
}

.grid-table .ag-theme-quartz .ag-header {
  background-color: #c1c1c1;
  border-bottom: 0;
  height: 30px !important;
  min-height: 30px !important;
}

.grid-table .ag-header-cell-text {
  font-weight: 700;
  font-family: Rubik;
  font-size: 12px;
  color: #000;
}

.grid-table .ag-theme-quartz .ag-row {
  background-color: white;
  border-color: #d4d4d4;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell {
  font-family: Rubik;
  font-size: 12px;
  background-color: transparent;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell.editable-cell-ndmp {
  border: 1px solid #267491 !important;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell.editable-cell-ndmp-green {
  border: 1px solid green !important;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell.editable-cell-ndmp-red {
  border: 1px solid red !important;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell select {
  border-color: #e8ecef;
  font-family: Rubik;
  font-size: 12px;
  padding: 1px;
  border-radius: 3px;
}

.grid-table .ag-paging-panel.ag-unselectable {
  font-family: Rubik;
  color: #333;
  font-size: 12px;
  font-weight: 700;
}

.grid-table .ag-cell-focus,
.grid-table .ag-cell {
  border: none !important;
}

.grid-table .ag-body-viewport::-webkit-scrollbar,
.grid-table .ag-root-wrapper.ag-layout-normal.ag-ltr::-webkit-scrollbar {
  width: 12px;
}

.grid-table .ag-body-viewport::-webkit-scrollbar-track,
.grid-table.ag-body-viewport::-webkit-scrollbar-thumb,
.grid-table .ag-root-wrapper.ag-layout-normal.ag-ltr::-webkit-scrollbar-track,
.grid-table .ag-root-wrapper.ag-layout-normal.ag-ltr::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

.grid-table .ag-body-viewport::-webkit-scrollbar-thumb,
.grid-table .ag-root-wrapper.ag-layout-normal.ag-ltr::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #e4e4e4;
  border: 3px solid transparent;
  min-height: 50px;
}

.grid-table .ag-body-viewport::-webkit-scrollbar:horizontal,
.grid-table .ag-root-wrapper.ag-layout-normal.ag-ltr::-webkit-scrollbar:horizontal {
  height: 12px;
}

.modal.show {
  display: block !important;
  opacity: 1;
}

.meas_sch .mat-mdc-form-field:nth-of-type(2n) .mat-mdc-form-field-infix {
  border-radius: 0 6px 6px 0 !important;
}

@media (min-width: 768px) {
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

i.icon-camera_outlined,
i.icon-videocam_outlined {
  font-size: 20px;
  font-weight: 500;
}

i.icon-certificate {
  font-size: 16px !important;
}

span.det_val {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#secondGrid .ps__rail-x,
.grid-table .ps__rail-x,
.grid-table .ps__rail-y,
#secondGrid .ps__rail-y {
  opacity: .6;
}

.ps_bar {
  position: relative;
}

.ps__thumb-y,
.ps__thumb-x {
  background-color: #267491 !important;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background: #E9F1F4 !important;
  opacity: 1 !important;
}


/*.ps_bar .ps__rail-x,*/
/*.ps_bar .ps__rail-y {*/
/*opacity: 0.6 !important;*/
/*display: block !important;*/
/*}*/

/* .ag-row:nth-child(even) .ag-cell,
.ag-row-even .ag-cell {
  background: #f1f1f1;
} */

/* .ag-row:nth-child(odd) .ag-cell,
.ag-row-odd .ag-cell {
  background: #fff;
} */

.modal .modal-dialog {
  margin: 0px auto;
}

.mat-expansion-panel-header {
  padding: 0 15px;
  max-height: 35px;
}

.mat-expansion-panel-body {
  padding: 10px 15px 0 !important;
  border-top: 1px solid #e0e0e0;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  margin-right: 0 !important;
}

.mat-expansion-panel-body p {
  margin-bottom: 0;
}

.ag-row.ag-row-selected {
  background: #99D3E9 !important;
}

.mat-expansion-panel-spacing {
  margin: 0 0px !important;
}

mat-expansion-panel.mat-expansion-panel {
  max-width: 220px;
  margin-right: 10px !important;
  width: 100%;
  display: inline-block !important;
  vertical-align: top;
  margin-bottom: 10px;
}

.mat-expansion-panel-header-title h4 {
  font-size: 14px !important;
  font-family: Rubik;
  font-weight: bold;
  margin: 5px 0;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.theme-btn:focus {
  color: #fff;
}

.dropdown-menu .ngb-dp-day,
.dropdown-menu .ngb-dp-week-number,
.dropdown-menu .ngb-dp-weekday {
  width: 3rem;
  height: 3rem;
}

.dropdown-menu .custom-select {
  font-size: 14px;
  height: 2.85rem;
}

.ag-theme-quartz .ag-row .ag-cell:first-child,
.ag-header-row .ag-header-cell:first-child {
  padding-left: 6px;
  padding-right: 5px;
}

.drop-link:hover ul {
  opacity: 1 !important;
}

.ag-theme-quartz .ag-row .ag-cell .blink_new_txt {
  /* -webkit-animation: blinkingText 1s infinite;
  animation: blinkingText 1s infinite; */
  font-size: 8px;
  margin-left: 5px;
  margin-top: -3px;
  position: absolute;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell.offer-cell {
  border: 1px solid #ddd !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  width: 60px !important;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell.remark-cell {
  border: 1px solid #ddd !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  width: 184px !important;
}

.cdk-overlay-container iframe.det_vdeo {
  min-height: 510px;
  max-width: 430px;
  border: 0;
}

.cdk-overlay-container iframe.det_video {
  min-height: 470px;
  max-width: 525px;
  width: 500px;
  border: 0;
}

.cdk-overlay-container img {
  max-width: 600px;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: RGB(38, 116, 145);
}



::ng-deep .mat-mdc-checkbox {
  padding: -1px 18px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: RGB(38, 116, 145);
}

.mat-calendar-body-selected {
  background-color: #267491;
  color: #fff;
}

.mat-datepicker-toggle-active {
  color: #267491 !important;
}

.mat-mdc-form-field-label {
  color: #267491 !important;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell:first-child {

  padding-left: 6px;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #267491;
}

mat-form-field.mat-form-field.mat-white .mat-focused .mat-form-field-label {
  color: #FAFAFA;
}

mat-form-field.mat-mdc-form-field.mat-white .mat-focused .mat-mdc-form-field-ripple {
  background-color: #FAFAFA !important;
}

.mat-mdc-form-field-label,
.mat-mdc-form-field-label.mat-focused,
.mat-mdc-form-field.mat-mdc-focused.mat-mdc-form-field-should-float {
  color: #FAFAFA;
}

.mat-mdc-form-field-underline,
.mat-mdc-form-field-underline.mat-focused {
  background-color: #FAFAFA;
}

.mat-mdc-form-field-underline {
  background-color: #bdc3c7 !important;
}

.mat-mdc-form-field-ripple {
  background-color: #267491 !important;
  ;
}

.mat-mdc-focused .mat-mdc-form-field-label {
  color: #267491 !important;
}

.mat-mdc-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-mdc-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #267491;
}

.mat-mdc-checkbox.mat-focused {
  background-color: #267491;
}

.ag-header-cell.text-center .ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell.text-right .ag-header-cell-label {
  justify-content: flex-end;
}

.ag-header-cell.text-left .ag-header-cell-label {
  justify-content: flex-start;
}

.ag-theme-quartz .ag-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ag-theme-quartz .ag-header-cell,
.ag-theme-quartz .ag-header-group-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ad-tab-content .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.ad-tab-content .mat-form-field {
  text-align: right;
}

.ad-tab-content .mat-select-arrow {
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top: 1px solid #757575;
  border-right: 1px solid #757575;
  margin: 5px 1px;
  height: 8px;
  width: 8px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  color: #757575;
}

.ad-tab-content .mat-select-value {
  font-size: 12px;
  text-align: left;
}


.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #267491;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #267491;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #267491;
}

.ag-cell.not-ava .ag-selection-checkbox .ag-checkbox-input-wrapper::after {
  border: 2px solid #ECABB7;
  color: transparent;
  width: 17px;
  height: 17px;
  border-radius: 5px;
}

.ag-cell.av-di .ag-selection-checkbox .ag-checkbox-input-wrapper::after {
  border: 1px solid #333;
  color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.av-di .ag-checkbox-input-wrapper.ag-checked::after {
  content: "✔";
  color: #333333;
  top: 0px;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 11.5px;
  text-align: center;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.av-di .ag-checkbox-input-wrapper {
  font-size: 16px;
  line-height: 16px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 2px;
}

.not-ava .ag-selection-checkbox.ag-hidden {
  display: block !important;
}

.ag-theme-quartz .ag-cell.not-ava .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper {
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  box-shadow: none;
  border-radius: 2px;
}

.ag-theme-quartz.ag-cell.not-ava .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper::after {
  color: transparent;
  width: 16px;
  height: 16px;
  top: 5px;
  border-radius: 2px;
}

.grid-table .ag-theme-quartz .ag-row .ag-cell i {
  pointer-events: none;
}

.dm-table .ag-cell span button {
  background: transparent;
  border: 0;
  font-size: 18px;
  color: #267491;
}

.mm-flex-row .tox.tox-tinymce {
  height: 200px !important;
}

.mm-flex-row .tox .tox-mbtn,
.mm-flex-row .tox .tox-tbtn {
  height: 20px;
  font-size: 12px;
}

.mm-flex-row .tox .tox-menubar+.tox-toolbar,
.mm-flex-row .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary {
  border-top: 1px solid #ccc;
  margin-top: 0;
  border-bottom: 1px solid #ccc;
}

.mm-flex-row .tox .tox-statusbar {
  border: 0;
}

.mm-flex-row .tox .tox-statusbar__text-container {
  display: none;
}

.register_mod .mat-checkbox.mb_regs {
  display: block;
}

.ag-theme-quartz .ag-cell.not-ava .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper {
  background-color: transparent;
}

button.ua-ac-btn {
  background: transparent;
  border: 0;
  font-size: 18px;
  color: #267491;
}

button.ua-ac-btn.disb-btn {
  opacity: .3;
}

.det_val span {
  cursor: pointer;
}

a {
  color: #267491;
  text-decoration: none;
}

.ag-cell.not-ava .ag-cell-wrapper .ag-selection-checkbox {
  pointer-events: none;
}

.fluorsm a.sch_tp {
  margin-top: 0 !important;
}

.swal2-content input {
  margin-top: 0 !important;
  margin-right: 5px !important;
}

.swal2-styled.swal2-confirm {
  background-color: #267491 !important;
}

.swal2-icon.swal2-info {
  border-color: #267491 !important;
  color: #267491 !important;
}

/*app-root[aria-hidden="true"] section#content-wrapper {*/
/*filter: blur(2px);*/
/*}*/
/*app-root[aria-hidden="true"] div[id="wrapper"] .sidebar-nav, app-root[aria-hidden="true"] div[id="wrapper"] .navbar{*/
/*filter: blur(2px);*/
/*}*/
.searchNcopy a {
  color: #267491 !important;
}

span.det_val span {
  margin-top: 3px;
}

::placeholder {
  font-size: 10px;
}

.ag-cell p {
  margin-top: 5px;
  margin-bottom: 0;
}

.modal-backdrop {
  background-color: transparent !important;
}

.sc-box .mat-form-field-flex {
  padding: 0 !important;
  background: transparent;
}

.sc-box .mat-form-field-flex .mat-form-field-infix {
  padding: .25em 0 .40em 0 !important;
}

.parcle-goods-page .mat-tab-group.mat-primary .mat-ink-bar,
.parcle-goods-page .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #185268;
  height: 0;
}

.parcle-goods-page .mat-tab-label.mat-tab-label-active {
  background: #267491;
  opacity: 1;
  color: #fff;
}

.parcle-goods-page .mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.tab-holder {
  padding: 20px;
}

.parcle-goods-page .mat-tab-nav-bar,
.parcle-goods-page .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  border-top: 1px solid rgba(0, 0, 0, .12);
}

.parcle-goods-page .mat-radio-button {
  width: 100%;
  margin-bottom: 15px;
}

.parcle-goods-page .mat-tab-group {
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
}


@media (max-width:1600px) {
  .result_inline .mat-chip {
    margin-bottom: 5px !important;
  }
}



.bm-filt-row .mat-form-field {
  width: 100%;
}

.bm-filt-row .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 4px 0;
}

.bm-filt-row .form-group {
  margin-bottom: 0;
}

.bm-filt-row {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar-video-container video {
  width: 100%;
  height: 100%;
}

.sidebar-nav svg {
  fill: #333;
}

.sidebar-nav a:hover svg {
  fill: #fff;
}

.sidebar-nav a:hover svg,
.n-s a:hover svg path,
.n-s a:hover svg polygon,
li.n-s.active>a svg polygon,
li.n-s.active>a svg path {
  fill: #fff !important;
}

.vd-img-holder.upcome_stone img {
  width: auto;
  max-width: 100%;
}

canvas#particle-canvas {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  pointer-events: none;
}

div#canvas-holder {
  pointer-events: none;
}


.ndmp-table i.icon-up_arrow.arred_view {
  color: red;
}

.ndmp-table i.icon-down-arrow.ar_view {
  color: green;
}

::ng-deep .mat-mdc-standard-chip {
  background: #FFFFFF;
  border: 1px solid #E8ECEF;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px;
  margin: 2px;
}


.result_inline .mat-chip-list-wrapper {
  width: 100%;
}

.result_inline .mat-chip.mail-btn {
  margin-left: auto !important;
}

.result_inline .mat-chip.mail-btn .res_icon {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .date_sch .mat-mdc-form-field-infix {
    width: 170px;
  }

  .main_logo svg {
    max-width: 250px !important;
  }

  .org_mat .mat-mdc-form-field-infix {
    width: 180px;
    margin-top: 10px;
  }

  .crt-holder .mat-chip-list-wrapper {
    margin-top: 15px !important;
  }

  .sch_whfc.sel-type a {
    padding: 8px 15px !important;
    font-size: 13px;
  }
}

@media (max-width:991px) and (min-width:768px) {
  .de_pr .user_pr {
    width: 50% !important;
  }

  .nav_hed.navbar-header {
    width: 80px !important;
  }

  .grp-search {
    width: 200px !important;
  }

  .dash {
    font-size: 16px !important;
  }

  .vd-img-holder video {
    margin: 0 auto;
  }

  .mail_schic {
    width: 32px !important;
  }
}

.modal {

  z-index: 999;
}


.ag-theme-quartz .ag-cell p.red-line,
.red-line {
  position: relative;
  display: inline-block;
}

.upcome_stone4 .red-line.sm-line::before {
  width: 27px;
  left: 36%;
}

.ag-theme-quartz .ag-cell p.red-line::before,
.red-line::before {
  content: '';
  width: 100%;
  position: absolute;
  left: -2px;
  height: 20px;
  top: -11px;
  border-bottom: 1px solid rgba(255, 0, 0, .5);
  transform: rotate(-9deg);
}

.ag-theme-quartz .ag-cell p.red-line.sm-line::before,
.red-line.sm-line::before {
  height: 10px;
  top: 0;
  width: 50px;
  left: 50%;
  transform: translateX(-50%) rotate(-9deg);
}

.nd-rem-timer .mat-chip-list .mat-chip {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 10px !important;
}

.fill-input .mat-form-field {
  width: 100%;
}

.swal2-content {
  font-size: 14px !important;
}


.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 9999;
}

@media only screen and (max-height:700px) {
  a.sidebar-video-container {
    height: 210px !important;
    min-height: auto;
  }
}

@media only screen and (height:700px) {
  a.sidebar-video-container {
    height: 210px !important;
    min-height: auto;
  }
}


/* 24-09-2021 for ISNPD Stone */
.ag-cell.not-ava-npd .ag-selection-checkbox .ag-checkbox-input-wrapper::after {
  border: 2px solid #00D100;
  color: transparent;
  border-radius: 2px;
}

.not-ava-npd .ag-selection-checkbox.ag-hidden {
  display: block !important;
}

.ag-theme-quartz .ag-cell.not-ava-npd .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper {
  font-size: 16px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  box-shadow: none;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.not-ava-npd .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper::after {
  color: transparent;
  width: 16px;
  height: 16px;
  top: 5px;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.not-ava-npd .ag-selection-checkbox.ag-hidden .ag-checkbox-input-wrapper {
  background-color: transparent;
}

.ag-cell.not-ava-npd .ag-cell-wrapper .ag-selection-checkbox {
  pointer-events: none;
}

.ag-theme-quartz .ag-row .ag-cell .bid_icon {
  font-size: 12px;
  margin-left: 5px;
  margin-top: -3px;
  position: absolute;
}

/* 21-10-2021 */

.ag-theme-quartz .ag-header-row.ag-header-row-floating-filter {
  height: 32px !important;
  top: 0 !important;
  background: #f1f1f1;
}


.grid-table.notify-tkt .ag-header-row.ag-header-row-column {
  height: 32px !important;
  top: 32px !important;
}

.grid-table.notify-tkt .ag-header {
  height: 64px !important;
  min-height: 64px !important;
}

.grid-table.notify-tkt .ag-header-row.ag-header-row-column {
  height: 32px !important;
  top: 32px !important;
}

.grid-table.notify-tkt .ag-theme-quartz .ag-header-icon {
  display: none;
}

/* .grid-table.notify-tkt .ag-cell-wrapper {
  justify-content: center;
} */

.grid-table.notify-tkt .ag-theme-quartz .ag-ltr .ag-selection-checkbox {
  margin-right: 0;
}

.grid-table.notify-tkt .ag-header-cell-text {
  overflow: initial;
  text-overflow: clip;
  white-space: pre-wrap;
}




/* margin media query  */

.m-auto {
  margin: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}


.ml-auto {
  margin-left: auto !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}


.mt-auto {
  margin-top: auto !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}


.mr-auto {
  margin-right: auto !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}


.mb-auto {
  margin-bottom: auto !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

/* margin media query  */

/* padding media query */

.p-auto {
  padding: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}


.pl-auto {
  padding-left: auto !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}


.pt-auto {
  padding-top: auto !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}


.pr-auto {
  padding-right: auto !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}


.pb-auto {
  padding-bottom: auto !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}


/*  padding media query */

.w-100 {
  width: 100% !important;
}

/* .row {
   display: flex;
 } */

.d-flex {
  display: flex;
}


.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(10%) !important;
}


.mat-badge-content {
  color: #fff;
  background: #ef6c00 !important;
}



.ag-cell.av-di-nda .ag-selection-checkbox .ag-checkbox-input-wrapper::after {
  border: 2px solid #5e0060;
  color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.av-di-nda .ag-checkbox-input-wrapper.ag-checked::after {
  content: "✔";
  color: #5e0060;
  top: 0px;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 11.5px;
  text-align: center;
  border-radius: 2px;
}

.ag-theme-quartz .ag-cell.av-di-nda .ag-checkbox-input-wrapper {
  font-size: 16px;
  line-height: 16px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 2px;
}



/* matiral change  */

.mat-mdc-dialog-container {
  background: #fff;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: #fff;
  box-shadow: none !important;
}

.mat-mdc-select-panel {
  background: #fff;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}



/* ag grid with quartz theme start */
.ag-theme-quartz {
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
}

.ag-theme-quartz .ag-header-cell {
  padding: 0px 5px !important;
  font-size: 12px !important;
}

.ag-theme-quartz .ag-cell-value.ag-cell {
  padding: 0px 5px !important;
  font-size: 12px !important;
}


/* ag grid with quartz theme end */



















.hd_border .mat-mdc-standard-chip {
  /* border: none !important; */

  padding: 27px 14px;

}

.hd_border .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  display: flex;
  align-items: center;
}

.hd_border .mat-mdc-standard-chip:last-child {
  border-radius: 0 10px 10px 0 !important;

}

.hd_border .mat-mdc-standard-chip:first-child {
  border-radius: 10px 0 0 10px !important;

}

.hd_border .mat-mdc-standard-chip {
  border-radius: 0px;
}

.hd_border .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
  padding: 0px;
}


.hd_border .mdc-evolution-chip-set .mdc-evolution-chip {
  margin: 0px;
}


.hd_border .mat-mdc-chip-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  /* background: #267491 !important; */
  background: #267491 !important;
  border: 1px solid #267491 !important;
  color: #fff !important;
  transition: none;
}

.hd_border .mdc-evolution-chip-set__chips {
  row-gap: 4px;
}

.hd_border .mat-mdc-chip-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .hd_shp {
  color: #fff !important;
}

.hd_border .mat-mdc-chip-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .hd_diam {
  color: #fff !important;
}

.flr_sm1 .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding: 0px;
  /* p: 0px; */

}

.flr_sm1 .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #267491;
}

::ng-deep .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before,
::ng-deep .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
::ng-deep .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #267491 !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #267491 !important;
}

::ng-deep .date_sch .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: none !important;
}


/* .flr_sm1 .mat-mdc-text-field-wrapper {
  height: 40px;
} */

/* 
.date_sch .mat-mdc-text-field-wrapper {
  height: 40px;
} */

.date_sch .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 0px;
}

.date_sch .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom: none !important;
}

.date_sch .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  display: flex;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.date_sch .mat-mdc-form-field-infix {
  min-height: 41px;
}

.flr_sm1 .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0px;
}

.date_sch .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.date_sch .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.date_sch .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e8ecef;
}

.flr_sm1 .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.flr_sm1 .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.flr_sm1 .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e8ecef;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  color: #267491 !important;
  font-size: 12px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: #267491 !important;
}

::ng-deep .mat-calendar-body-selected {
  background-color: #267491 !important;
}



::ng-deep .mat__input .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
  display: flex !important;
  min-height: 40px !important;
}

::ng-deep .mat-input mat-label {
  font-size: 14px;
}

::ng-deep .mat__input .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
  display: flex;
}

::ng-deep .mat__input .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
  display: flex !important;
}

::ng-deep .mat__input .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix .mat-mdc-input-element {
  font-size: 13px;
}

::ng-deep .mat__input .mat-mdc-select {
  display: flex;
}

::ng-deep .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

::ng-deep .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px !important;
  font-size: 13px;
}

::ng-deep .mdc-notched-outline .mdc-floating-label--float-above {
  overflow: initial !important;

}

::ng-deep .mat-mdc-select-min-line {
  font-size: 13px;
}

::ng-deep .mat__input .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 1px !important;
  width: 32px !important;
  height: 32px !important;
}

::ng-deep .mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
  /* padding: 2px !important; */
  width: 16px !important;
  height: 16px !important;
}

::ng-deep .mat-mdc-outlined-button:not(:disabled) {
  color: #267491 !important;
}

::ng-deep .mat-calendar-body-selected {
  background-color: #267491 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio {
  padding: 0px !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #267491 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #267491 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio {
  height: -webkit-fill-available !important;
}

::ng-deep .mat-mdc-radio-button .mdc-form-field {
  margin-right: 6px !important;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  width: 138px !important;
}

::ng-deep .mat-mdc-form-field-infix {
  min-height: 0px !important;
}

::ng-deep .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

::ng-deep .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

::ng-deep .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

::ng-deep .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: #267491 !important;
  color: #fff !important;
}

::ng-deep .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
  opacity: 0 !important;
}

::ng-deep .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: transparent !important;
}

::ng-deep .mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
  opacity: 0 !important;
}

::ng-deep .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background {
  border-color: #267491 !important;
  background-color: #267491 !important;

}



























::ng-deep .input_h .mat-mdc-form-field-flex {
  height: 55px !important;
}

::ng-deep .input_h .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
::ng-deep .input_h .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 86px !important;
}

::ng-deep .input_h .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  font-size: 12px !important;
  color: #c6c1c1 !important;
}

::ng-deep .input_h .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-infix {
  padding: .4375em 0 !important;
}

::ng-deep .mat-mdc-unelevated-button:not(:disabled) {
  background-color: #267491 !important;
}

::ng-deep .input_b .mat-mdc-outlined-button:not(:disabled) {
  color: #267491 !important;
}




/* 
::ng-deep .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
  border-bottom-color: #267491 !important;  
  --mdc-filled-text-field-error-hover-active-indicator-color: #267491 !important;  
}
::ng-deep .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before {
  border-bottom-color: #267491 !important; 
}
::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
  border-bottom-color: #267491 !important; 
} */


/* ::ng-deep .date_sch .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before{
  border-bottom-color: none !important;
    
} */




.mdc-text-field--filled {
  background-color: #fff !important;
  --mdc-filled-text-field-container-color: #fff !important;
}

.mdc-text-field--filled {
  background-color: #fff !important;
  --mdc-filled-text-field-container-color: #fff !important;
}

.mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}


::ng-deep .date_sch .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: none !important;
}

::ng-deep .date_sch .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: red !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #267491 !important;
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #267491 !important;
}








.flt_rgh .mat-mdc-standard-chip .mdc-evolution-chip__action--primary:before {
  background: #e0e0e0 !important;
}

/* .mf_gender .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #267491 !important;

} */
.gender_chip .mat-mdc-chip-selected .mdc-evolution-chip__text-label {
  color: #fff !important;
}

.gend_male .mdc-evolution-chip__text-label {
  color: #267491 !important;
}

.mat-body-2,
.mat-body-strong {
  font: 500 14px / 24px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}


/* ::ng-deep .mdc-evolution-chip-set__chips .mat-mdc-standard-chip {
  margin: 0 1px 0 0 !important;
} */
/* 

::ng-deep .mat-mdc-form-field-infix{
  min-height: 0px !important;
} */





/* ::ng-deep .ord_min .mdc-evolution-chip-set .mdc-evolution-chip-set__chips{
  column-gap: 0px !important;
} */


.flr_sm1 .mdc-evolution-chip-set .mdc-evolution-chip-set__chips,
.padding-dis .mdc-evolution-chip-set .mdc-evolution-chip-set__chips,
.dsres_tp .mdc-evolution-chip-set .mdc-evolution-chip-set__chips,
.t_pcs .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  column-gap: 1px !important;
  row-gap: 7px !important;
}

.t_pcs .mdc-evolution-chip__cell {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}





::ng-deep .mat__input .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0 !important;
  display: flex !important;
  min-height: 38px !important;
}

::ng-deep .mat__input .mat-mdc-select {
  display: flex;
  align-items: center;

}

::ng-deep .mat__input .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
  display: flex !important;
}

::ng-deep .mat__input .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  padding: 0px !important;

}

::ng-deep .mat__input .mat-mdc-text-field-wrapper {
  padding: 0 10px !important;
}

::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #267491 !important;
}

::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input,
::ng-deep .mat-mdc-select-value-text {
  font-size: 14px !important;
}













/* 25/6 ----- 12.48 */

/* .date_sch .mat-mdc-form-field-flex {
  border: 1px solid #e8ecef;
  border-radius: 6px;
  margin-left: 0;
} */

/* ----------------------------------- radio -button - css  -------------------------- */
::ng-deep .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
  opacity: .0 !important;

}

::ng-deep .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: transparent !important;
}

::ng-deep .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  background-color: transparent !important;
}

::ng-deep .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element {
  background-color: transparent !important;
}

::ng-deep .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: transparent !important;
  opacity: .0 !important;
}

::ng-deep .mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
  background-color: transparent !important;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0 !important;
}


.form-control {
  height: 38px !important;
}

.ag-sticky-top {
  top: 30px !important;
  position: absolute;
  width: 100% !important;
}















