@font-face {
  font-family: 'narola-home-web';
  src:  url('../fonts/narola-home-web.eot?am7ydj');
  src:  url('../fonts/narola-home-web.eot?am7ydj#iefix') format('embedded-opentype'),
    url('../fonts/narola-home-web.ttf?am7ydj') format('truetype'),
    url('../fonts/narola-home-web.woff?am7ydj') format('woff'),
    url('../fonts/narola-home-web.svg?am7ydj#narola-home-web') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Poppins';
  src: url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-bold-webfont.woff2') format('woff2'),
       url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Poppins';
  src: url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-light-webfont.woff2') format('woff2'),
       url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Poppins';
  src: url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
       url('https://pcknstg.blob.core.windows.net/hdfile/webimg/assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: .5px;
}

[class^="home-icon-"], [class*=" home-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'narola-home-web' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-icon-noun-tweezers:before {
  content: "\e900";
}
.home-icon-realVideo:before {
  content: "\e901";
}
.home-icon-Emerald:before {
  content: "\e902";
}
.home-icon-Heart:before {
  content: "\e903";
}
.home-icon-LgRadiant:before {
  content: "\e904";
}
.home-icon-Marquise:before {
  content: "\e905";
}
.home-icon-Oval:before {
  content: "\e906";
}
.home-icon-Pear:before {
  content: "\e907";
}
.home-icon-Princess:before {
  content: "\e908";
}
.home-icon-Round:before {
  content: "\e909";
}


body {
  font-family: Popins-Medium;
}

.brand {
  display: flex;
  position: absolute;
  text-align: center;
  margin: 0px auto;
  left: 0;
  right: 0;
  max-width: 260px;
  top: 15px;

}

a:hover, a:focus {
  text-decoration: none;
}

.finger {
  width: 26px;
  margin-top: -4px;
}

.log {
  margin-right: 5px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.log:hover, .log:active {
  text-decoration: none;
  color: #fff;
}

.ind_bg {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/main-top-bg.png) no-repeat;
  background-position: center;
  background-color: #000;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.dia_bg {
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dia_bg_2{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dia_bg_3{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ind_bg .container {
  z-index: 2;
  position: relative;
  height: 100%;
}
.dia_bg img, .dia_bg_2 img, .dia_bg_3 img{
  -webkit-animation: spinnerRotate 110s linear infinite;
  animation: spinnerRotate 110s linear infinite;
}
/*.dia_bg_2 img{*/
  /*-webkit-animation: spinnerRotate 70s linear infinite;*/
  /*animation: spinnerRotate 70s linear infinite;*/
  /*!*animation-delay: .1s;*!*/
/*}*/
/*.dia_bg_3 img{*/
  /*-webkit-animation: spinnerRotate 70s linear infinite;*/
  /*animation: spinnerRotate 70s linear infinite;*/
  /*!*animation-delay: .3s;*!*/
/*}*/
@media(min-width:1601px){
  .dia_bg {
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
  }
}
@media(max-width:1600px){
  .dia_bg_3 {
    -webkit-transform: scale(.777);
    transform: scale(.777);
  }
  .dia_bg_2 {
    -webkit-transform: scale(.88);
    transform: scale(.88);
  }
}
@media(max-width:1399px){
  .dia_bg {
    -webkit-transform: scale(.89);
    transform: scale(.89);
  }
  .dia_bg_3 {
    transform: scale(.75);
  }
}
@media(max-width:1023px){

  .dia_bg {
    -webkit-transform: scale(.6);
    transform: scale(.6);
  }
}
@media(max-width:1199px) and (min-width:1024px){
  .dia_bg {
    -webkit-transform: scale(.8);
    transform: scale(.8);
  }
}

/*spinner keyframe
=======================================================*/
@-webkit-keyframes spinnerRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinnerRotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.nav_nv {
  padding-top: 40px;
}

.nav_bar {
  color: #fff;
}

.main_logo {
  text-align: center;
  padding: 0 0px !important;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
}

.bo_menu {
  z-index: 2;
}

.bo1 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 355px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.bo2 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 379px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.bo3 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 403px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.bo4 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 427px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.bo5 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 452px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.bo6 {
  position: fixed;
  width: 10px;
  height: 0px;
  right: 40px;
  top: 478px;
  border: 2px solid rgba(44, 60, 74, 0.9);
  cursor: pointer;
}

.border_row .nav a.active {
  width: 24px;
  border: 2px solid rgba(255, 255, 255, 0.75);
}

.ab_bg {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/about.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 940px;
  background-repeat: no-repeat;
}

.crf_bg {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/craft.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 940px;
  background-repeat: no-repeat;
}

.csr_bg {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/csr.jpg) no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 940px;
  background-repeat: no-repeat;
}

.pro_plr {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
p.frg {
  margin-top: 10px;
  font-size: 12px;
}
.prbg_plr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pro_bg {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/pr-bg-1.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-repeat: no-repeat;
}

.pro_bg1 {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/pr-bg-2.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-repeat: no-repeat;
}

.cont_bg1 {
  width: 100%;
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/cont2.png) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  background-repeat: no-repeat;
}



.cont_bg1 .office {
  position: absolute;
  top: 50%;
  left: 45px;
  padding: 0;
  transform: translateY(-50%);
  z-index: 2;
}
.ml_femgend .mat-standard-chip:focus::after {
  opacity: .01;
}

.ml_femgend .mat-standard-chip:hover::after {
  opacity: .01;
}

.contact_map {
  width: 100%;
  height: 100vh;
  border: 0;
  opacity: 0.7;
}

.prod_mtxt {
  font-size: 14px;
  font-weight: 100;
  color: #010306;
  font-family: "Poppins", sans-serif;
  display: block;
  margin-top: 20px;
}

.prod_mtxt1 {
  font-size: 14px;
  font-weight: 100;
  color: #fff;
  font-family: "Poppins", sans-serif;
  display: block;
  margin-top: 20px;
}

.certi_img {
  padding-top: 0;
  padding-left: 120px;
  padding-right: 120px;
  text-align: center;
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  left: 0;
  right: 0;
}
.certi_img p {
  max-width: 360px;
  margin: 0 auto;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
.certi_img h5 {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.prbg_plr .office p {
  padding-left: 0;
}
.certi_img img {
  width: 100%;
  max-width: 410px;
}

.csr_img {
  padding-top: 160px;
}

.csrpr {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.csr_fig img {
  width: 100%;
}

.ab_head {
  /*font-family: "Marcellus", serif;*/
  font-size: 48px;
  text-transform: uppercase;
}

.craft_head {
  /*font-family: "Marcellus", serif;*/
  font-size: 48px;
}

.about_sec {
  color: #293d49;
  text-align: center;
  padding-top: 600px;
}

.craft_sec {
  color: #fff;
  text-align: center;
  padding-top: 500px;
}

.csr_sec {
  color: #fff;
  text-align: center;
  padding-top: 40px;
}

.csr_head {
  /*font-family: "Marcellus", serif;*/
  font-size: 48px;
}

.pro_head {
  /*font-family: "Marcellus", serif;*/
  font-size: 48px;
  color: #fff;
  margin-top: 25px;
}

.office {
  padding: 250px 44px 20px;
}

.off_head {
  /*font-family: "Marcellus", serif;*/
  font-size: 42px;
  color: #fff;
}

.off_txt {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  margin: 30px 0px 0;
  color: #889fad;
}

.off_add {
  font-family: Poppins;
  font-weight: lighter;
  font-size: 16px;
  color: #d9e0e5;
}

.off_mail {
  font-family: Poppins;
  font-weight: lighter;
  font-size: 16px;
  color: #d9e0e5;
  text-decoration: none;
  cursor: pointer;
}

.off_mail:hover, .off_mail:active {
  font-family: Poppins;
  font-weight: lighter;
  font-size: 15px;
  color: #889fad;
  text-decoration: none;
  cursor: pointer;
}

.ab_para {
  font-size: 16px;
  font-weight: 400;
  color: #293d49;
  font-family: "Poppins", sans-serif;
  display: block;
  letter-spacing: .5px;
  max-width: 90%;
  margin: 20px auto;
}

.crf_para {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  font-family: "Poppins", sans-serif;
  display: block;
  max-width: 50%;
  margin: 0 auto;
  letter-spacing: .5px;
}
.csr_para {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  font-family: "Poppins", sans-serif;
  display: block;
  max-width: 60%;
  margin: 0 auto;
}

.procen {
  background-color: #597a86;
  z-index: 1;
}



.pro_para {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 100;
  color: #fff;
  font-family: "Poppins", sans-serif;
  display: block;
  max-width: 50%;
  margin: 0 auto;
}

.btn_arr {
  padding: 5px 20px;
  border: 1px solid #293d49;
  background-color: #293d49;
  color: #fff;
  border-radius: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.know_btn {
  margin-right: 10px;
}

button:focus {
  outline: none !important;
}



.procen p.pro_para {
  color: #fff;
}
.procen h3.pro_head {
  margin-top: 50px;
  color: #fff;
}
section#product .procen{
  position: absolute;
  background: transparent;
  bottom: 10%;
}
section#product {
  position: relative;
}

@media screen and (max-width: 1600px) {
  .ind_bg {
    height: 100vh;
  }



  .ab_bg {
    min-height: 900px;
  }

  .about_sec {
    padding-top: 500px;
  }
}

@media screen and (max-width: 1366px) {
  .ind_bg {
    height: 100vh;
  }

  .ab_bg {
    min-height: 850px;
  }

  .brand_logo {
    width: 400px;
  }
}

@media screen and (max-width: 1024px) {
  .cont_bg {
    min-height: 800px;
  }

  .cont_bg1 {
    min-height: 800px;
  }

  .contact_map {
    height: 800px;
  }

  .crf_bg {
    min-height: 760px;
  }

  .csr_bg {
    min-height: 730px;
  }

  .csr_img {
    padding-top: 100px;
  }

  .csr_sec {
    padding-top: 60px;
  }

  .csr_para {
    font-size: 15px;
  }

  .office {
    padding: 200px 44px 20px;
  }

  .pro_bg {
    min-height: 700px;
  }

  .pro_bg1 {
    min-height: 700px;
  }

  .certi_img {
    padding-top: 200px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .ind_bg {
    min-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .brand_logo {
    width: 300px;
  }

  .ab_bg {
    background: url("https://pcknstg.blob.core.windows.net/hdfile/webimg/about.png");
    min-height: 480px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .craft_sec {
    padding-top: 380px;
  }

  .about_sec {
    padding-top: 200px;
  }
}

@media screen and (max-width: 768px) {
  .office {
    padding: 100px 44px 20px;
  }

  .cont_bg {
    min-height: 700px;
  }

  .cont_bg1 {
    min-height: 700px;
  }

  .ab_para {
    font-size: 18px;
  }

  .contact_map {
    height: 700px;
  }

  .csr_bg {
    min-height: 450px;
  }

  .csr_img {
    padding-top: 80px;
  }

  .brand {
    margin: 0px 250px;
  }

  .certi_img {
    padding-top: 160px;
    padding-left: 35px;
    padding-right: 35px;
  }

  .brand_logo {
    width: 150px;
  }

  .pro_bg {
    min-height: 650px;
  }

  .pro_para {
    margin-top: 10px;
  }

  .pro_bg1 {
    min-height: 650px;
  }

  .pro_head {
    font-size: 30px;
  }

  .main_logo {
    padding: 150px 0px;
  }

  .ind_bg {
    min-height: 340px;
  }

  .craft_sec {
    padding-top: 330px;
  }

  .crf_para {
    font-size: 18px;
  }

  .crf_bg {
    min-height: 680px;
  }
}

@media screen and (max-width: 414px) {
  .office {
    padding: 100px 25px 20px;
  }

  .off_mail {
    font-size: 14px;
  }

  .about_sec {
    padding-top: 150px;
  }

  .off_add {
    font-size: 14px;
  }

  .res_pro {
    margin-top: 0px;
  }

  .crf_bg {
    min-height: 340px;
  }

  .cert_res {
    padding-top: 30px;
  }

  .pro_bg1 {
    min-height: 520px;
  }

  .brand {
    margin: 0px 150px;
  }

  .border_row {
    display: none;
  }

  .csr_head {
    font-size: 30px;
  }

  .csr_para {
    font-size: 9px;
  }

  .csrpr {
    padding-left: 15px !important;
    padding-right: 15px !important;;
  }

  .main_logo {
    padding: 100px 0px;
  }

  .brand_logo {
    width: 200px;
  }

  .ab_head {
    font-size: 30px;
  }

  .ind_bg, .ab_bg {
    min-height: 300px;
  }

  .nav_nv {
    padding-top: 5px;
  }

  .logo_sm {
    width: 150px;
    margin-left: -100px;
  }

  .ab_para {
    font-size: 9px;
  }

  .craft_head {
    font-size: 26px;
  }

  .crf_para {
    font-size: 9px;
  }

  .craft_sec {
    padding-top: 160px;
  }

  .pro_para {
    font-size: 16px;
  }

  .prod_mtxt, .prod_mtxt1 {
    text-align: justify;
  }
}

#button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  opacity: 1;
  visibility: visible;
  cursor: pointer;
  z-index: 1;
}

/* Modal */

.modal-title {
  font-family: 'Rubik';
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0px auto;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 410px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.register_mod {
  width: 620px !important;
  max-width: 620px !important;
}

@media screen and (max-width: 414px) {
  .register_mod {
    width: 370px !important;
  }
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 15px 15px;
}

/*Right*/

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.use_head {
  font-family: 'Rubik';
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #253646;
  font-size: 14px;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:first-child > .btn-group:not(:first-child) > .btn, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #DADADA;
  text-align: center;
  background-color: #fff;
  border: 1px solid #E8ECEF;
  border-radius: 10px;
  margin-left: -6px;
}

.inp_us {
  background: #FFFEFE;
  border: 1px solid #E8ECEF;
  border-right: 1px solid transparent;
  padding: 6px 20px;
  border-radius: 10px;
  font-family: 'Rubik';
  width: 272px;
  font-size: 12px;
}

.inp_us:focus {
  outline: none !important;
}

.user_login {
  margin-top: 50px;
}

.pswd_login {
  margin-top: 50px;
}

.frg {
  font-family: 'Rubik';
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  color: #253646;
  margin-right: 15px;
}

.btn_login {
  background: #267491 !important;
  border: 1px solid #267491 !important;
  border-radius: 10px !important;
  padding: 10px 30px !important;
  color: #fff !important;
}

.log_btn {
  text-align: center;
  margin-top: 40px;
}

.btn_login:hover, .btn_login:active {
  color: #fff;
  text-decoration: none;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.cret {
  margin-top: 30px;
  font-family: 'Rubik';
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  color: #253646;
  cursor: pointer;
}

.cret:hover, .cret:active, .cret:focus{
  color: #253646;
  text-decoration: none;
}

.ncr_icon {
  color: #757575;
  font-size: 15px;
  font-weight: bold;
}

.event_head {
  text-align: center;
  margin-top: 20px;
}

.event_head img {
  width: 100%;
  padding: 0px 10px;
}

.evnt {
  padding-left: 0px;
  padding-right: 0px;
}

.mod_head {
  border-bottom: 1px solid #267491;
}
button.cret {
  border: 0;
  background: transparent;
  display: block;
  text-align: center;
  margin: 20px auto;
}
a.cret {
  display: block;
}

.cls_mod {
  margin: -1rem !important;
  color: #757575;
  opacity: 1;
}

.conr {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 1600px) {
  .user_login {
    margin-top: 28px;
  }

  .pswd_login {
    margin-top: 30px;
  }

  .event_head img {
    padding: 0px 20px;
  }

  .btn_login {
    padding: 8px 28px !important;
  }

  .log_btn {
    margin-top: 30px;
  }

  .cret {
    margin-top: 24px;
  }

  .inp_us {
    width: 245px;
  }

  .inp_us {
    width: 250px;
  }
}

@media screen and (max-width: 1440px) {
  .user_login {
    margin-top: 20px;
  }

  .pswd_login {
    margin-top: 20px;
  }

  .cret {
    margin-top: 20px;
  }

  .log_btn {
    margin-top: 34px;
  }
}

@media screen and (max-width: 1366px) {
  .pswd_login {
    margin-top: 15px;
  }

  .user_login {
    margin-top: 15px;
  }

  .log_btn {
    margin-top: 15px;
  }

  .cret {
    margin-top: 20px;
  }

  .event_head {
    margin-top: 10px;
  }

  .modal.right .modal-body {
    padding: 10px 15px;
  }

  .event_head img {
    padding: 0px 52px;
  }
}

@media screen and (max-width: 1024px) {
  .event_head img {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 414px) {
  .modal.right .modal-dialog {
    width: 370px;
  }

  .inp_us {
    width: 248px;
  }

  .conr {
    padding-left: 16px;
    padding-right: 16px;
  }

  .modal.right .modal-dialog {
    width: 360px
  }

  .conr {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.procen h3.pro_head {
  margin-top: 50px;
}


.prbg_plr h5 {
  color: #fff;
  margin-top: 20px !important;
}
.prbg_plr p{
  color: #fff;
  padding: 0 70px;
}

#csr .csr-txt h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

#csr .csr-txt {
  margin-top: 20px;
}

#csr .csr-txt p {
  font-size: 13px;
  color: #fff;
  text-align: center;
  padding: 0 36px;
  line-height: 18px;
  font-weight: 300;
}
#contact .pro_plr {
  position: relative;
  overflow: hidden;
}
#contact .pro_plr::after {
  background: url(https://pcknstg.blob.core.windows.net/hdfile/webimg/gradiant.png) repeat-x top left;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  touch-action: none;
  opacity: .5;
}
body p{
  line-height: 160% !important;
}

.mdb_con .container.conr {
  z-index: 1;
  position: relative;
  background: #fff;
  padding-bottom: 40px;
}


